<template>
  <v-form ref="form" v-model="isFormValid">
    <v-dialog max-width="640px" value="true" persistent>
      <v-card>
        <v-card-title>新規制作案件</v-card-title>
        <v-divider />
        <v-card-text>
          <v-card flat>
            <v-card-text class="py-0">
              <!-- tabs button -->
              <v-tabs v-model="tab" show-arrows class="mt-6">
                <v-tab><v-icon left>mdi-domain-plus</v-icon>新規のクライアント</v-tab>
                <v-tab><v-icon left>mdi-domain</v-icon>契約中のクライアント</v-tab>
              </v-tabs>
              <v-divider class="mb-4" />
              <!-- tab contents -->
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-row dense>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field label="会社名" type="text" v-model="form.direction_customer_person_name" :rules="tab !== 0 ? [] : [inputRules.required]" class="required"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-textarea label="ご担当者名" v-model="form.direction_customer_person_name2" rows="1" auto-grow></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-textarea label="メールアドレス" v-model="form.direction_customer_mailaddress" rows="1" auto-grow></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-textarea label="お電話番号" v-model="form.direction_customer_tel" rows="1" auto-grow></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-text-field label="代理店名" type="text" v-model="form.agent_id"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select class="required" v-model="form.business_person_id" :rules="[inputRules.requiredSelect]" item-text="name" item-value="id" :items="adminCompanyLogins" label="営業担当" persistent-hint single-line clearable></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select v-model="form.direction_director_person_id" item-text="name" item-value="id" :items="adminCompanyLogins" label="担当ディレクター" persistent-hint single-line clearable></v-select>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-row dense>
                    <v-col cols="12" sm="6" md="6">
                      <v-select label="会社名" v-model="form.end_user_id" item-text="end_company_name" item-value="id" :items="endUsers" persistent-hint single-line :rules="tab !== 1 ? [] : [inputRules.requiredSelect]" class="required"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-textarea label="ご担当者名" v-model="form.direction_customer_person_name2" rows="1" auto-grow></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-textarea label="メールアドレス" v-model="form.direction_customer_mailaddress" rows="1" auto-grow></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-textarea label="お電話番号" v-model="form.direction_customer_tel" rows="1" auto-grow></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-text-field label="代理店名" type="text" v-model="form.agent_id"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select class="required" v-model="form.business_person_id" :rules="[inputRules.requiredSelect]" item-text="name" item-value="id" :items="adminCompanyLogins" label="営業担当" persistent-hint single-line clearable></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select v-model="form.direction_director_person_id" item-text="name" item-value="id" :items="adminCompanyLogins" label="担当ディレクター" persistent-hint single-line clearable></v-select>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn @click="close()" text>キャンセル</v-btn>
          <v-btn @click="send()" v-promise-btn color="primary" :disabled="!isFormValid">送信</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
      <v-overlay :value="isLoading" absolute>
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-dialog>
  </v-form>
</template>

<script>
import axios from '@/plugins/axios'

export default {
  name: 'DirectionDialog',
  props: { params: Object },
  data: () => ({
    isLoading: false,
    isFormValid: false,
    endUsers: [],
    adminCompanyLogins: [],
    form: {
      business_person_id: '',
      direction_director_person_id: '',
      agent_id: '',
      direction_customer_person_name: '',
      direction_customer_person_name2: '',
      direction_customer_mailaddress: '',
      direction_customer_tel: '',
      end_user_id: ''
    },
    tab: null
  }),
  watch: {
    tab: function () {
      this.$refs.form.resetValidation()
    }
  },
  created: function () {
    this.readDataFromAPI()
    this.form.business_person_id = this.params.business_person_id ?? ''
    this.form.direction_director_person_id = this.params.direction_director_person_id ?? ''
    this.form.agent_id = this.params.agent_id ?? ''
    this.form.direction_customer_person_name = this.params.direction_customer_person_name ?? ''
    this.form.direction_customer_person_name2 = this.params.direction_customer_person_name2 ?? ''
    this.form.direction_customer_mailaddress = this.params.direction_customer_mailaddress ?? ''
    this.form.direction_customer_tel = this.params.direction_customer_tel ?? ''
    this.form.end_user_id = this.params.end_user_id ?? ''
    if (this.params.end_user_id) {
      this.tab = 1
    }
  },
  methods: {
    readDataFromAPI: function () {
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user`, {
        params: {
          quicksearch: 'in_contract',
          per_page: 1000
        }
      })
        .then(res => {
          this.endUsers = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/login`, {
        params: {
          per_page: 1000
        }
      })
        .then(res => {
          this.adminCompanyLogins = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    send: function () {
      const self = this
      if (self.tab === 0) {
        self.form.end_user_id = ''
      } else if (self.tab === 1) {
        self.form.direction_customer_person_name = 'DUMMY'
      }
      if (self.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('_method', 'POST')
        formData.append('business_person_id', self.form.business_person_id ?? '')
        formData.append('direction_director_person_id', self.form.direction_director_person_id ?? '')
        formData.append('agent_id', self.form.agent_id ?? '')
        formData.append('direction_customer_person_name', self.form.direction_customer_person_name ?? '')
        formData.append('direction_customer_person_name2', self.form.direction_customer_person_name2 ?? '')
        formData.append('direction_customer_mailaddress', self.form.direction_customer_mailaddress ?? '')
        formData.append('direction_customer_tel', self.form.direction_customer_tel ?? '')
        formData.append('end_user_id', self.form.end_user_id ?? '')
        return new Promise(function (resolve, reject) {
          self.isLoading = true
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction`, formData)
            .then((res) => {
              self.$store.commit('setFlashSuccess', '制作案件を登録しました。')
              resolve(res)
            })
            .catch(err => {
              self.$store.commit('setFlashError', self.createApiValidErrorMessage(err.response))
              reject(err)
            })
            .finally(() => {
              self.isLoading = false
              self.close()
            })
        })
      }
    },
    close: function () {
      this.$emit('close')
    }
  }
}
</script>
